<template>
  <div>
    <v-dialog v-model="shower" scrollable persistent max-width="500px">
      <v-form ref="formExcludeInvoiceExcel" v-model="valid" lazy-validation>
        <v-card >
            <v-card-title>Exclude Invoice ด้วย Excel</v-card-title>
            <v-divider></v-divider>
             <v-card-text style="height: 300px;">
                <v-layout row wrap align-center justify-center>
                    <v-flex lg11 xs11>
                        <v-subheader class="pa-0">รูปแบบการ Exclude</v-subheader>
                        <v-radio-group v-model="typeexclude" row class="ma-0 pb-2" :rules="[ v => !!v || 'กรุณาเลือกรูปแบบการ Exclude']" :disabled="processing">
                            <v-radio
                                label="Document ID"
                                value="docid"
                            ></v-radio>
                            <v-radio
                                label="Account"
                                value="account"
                            ></v-radio>
                            <v-radio
                                label="Name"
                                value="name"
                            ></v-radio>
                            </v-radio-group>

                    </v-flex>
                    <v-flex lg11 xs11 v-if="typeexclude !== 'docid'">
                      <v-dialog
                        ref="dialogdocdate"
                        v-model="modaldocdate"
                        :return-value.sync="docdate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="docdate"
                            label="เอกสารลงวันที่"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[ v => !!v || 'กรุณาเลือก File input']"
                            outlined
                            :disabled="processing"
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="docdate"
                          scrollable
                          color="amber"
                          type="month"
                          @input="modaldocdate = false, $refs.dialogdocdate.save(docdate)"
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg11 xs11>
                        <br>
                         <v-file-input
                            :rules="[ v => !!v || 'กรุณาเลือก File input']"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            v-model="files"
                            label="ไฟล์ Excel"
                            show-size
                            counter
                            :disabled="processing"
                            outlined
                            dense
                        ></v-file-input>
                    </v-flex>
                     <v-flex offset-lg7 lg4 xs11 class="text-right">
                        <v-btn color="info" disabled> ตัวอย่างไฟล์</v-btn>
                    </v-flex>
                </v-layout>
             </v-card-text>
             <v-divider></v-divider>
             <v-card-actions> <v-spacer></v-spacer> <v-btn :loading="processing" color="success" @click="fn_excludedata">ดำเนินการ</v-btn>  <v-btn outlined color="error" :disabled="processing" @click="fn_closedialog">ปิด</v-btn> </v-card-actions>
        </v-card>
        </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Swal2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
const Toast = Swal2.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})
export default {
  props: ['show'],
  name: 'excludeinvoicebyexcel',
  data: function () {
    return {
      docdate: '',
      typeexclude: 'docid',
      valid: true,
      modaldocdate: false,
      test: '',
      dataexcludeinvoice: [],
      processing: false,
      files: null
    }
  },
  computed: {
    shower: {
      get () {
        if (this.show === true) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        }
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('closedialog')
        }
      }
    }
  },
  methods: {
    fn_closedialog () {
    //   const checkSuccess = this.dataexcludeinvoice.findIndex(r => r.status === 'Success')
    //   if (checkSuccess !== -1) {
    //     this.$emit('reloaddata')
    //   }
      this.$emit('closedialog')
      if (!this.$refs.formExcludeInvoiceExcel.validate()) {
        this.$refs.formExcludeInvoiceExcel.reset()
      }
    },
    async fn_excludedata () {
      if (!this.$refs.formExcludeInvoiceExcel.validate()) {
        console.log(this.$refs.formExcludeInvoiceExcel.validate())
        console.log('VALIDATE')
      } else {
        this.processing = true
        const urlExcludeDocid = process.env.VUE_APP_SERVICE_BE_WHT_API + '/portal/exclude_by_docid'
        const urlExcludeAccount = process.env.VUE_APP_SERVICE_BE_WHT_API + '/portal/exclude_by_account'
        const urlExcludeName = process.env.VUE_APP_SERVICE_BE_WHT_API + '/portal/exclude_by_name'
        var bodyFormData = new FormData()
        if (this.typeexclude === 'docid') {
          bodyFormData.append('excel_file', '')
          // bodyFormData.append('excel_file', this.files)
        } else {
          // bodyFormData.append('excel_file', this.files)
          bodyFormData.append('excel_file', '')
          bodyFormData.append('process_month', this.fn_formatdatemonth(this.docdate))
          console.log(this.fn_formatdatemonth(this.docdate))
        }
        try {
          const response = await this.axios.post(this.typeexclude === 'docid' ? urlExcludeDocid : this.typeexclude === 'account' ? urlExcludeAccount : urlExcludeName, bodyFormData)
          this.processing = false
          if (response.data.status === 'OK') {
            Toast.fire({
              icon: 'success',
              title: 'Exclude Invoice เรียบร้อย'
            })
            this.fn_closedialog()
            this.$emit('reloaddata')
          } else {
            Toast.fire({
              icon: 'warning',
              title: response.data.errorMessage
            })
          }
        } catch (ex) {
          this.processing = false
          console.log(ex)
          Toast.fire({
            icon: 'error',
            title: JSON.stringify(ex)
          })
        }
      }
    },
    fn_formatdatemonth (_monthly) {
      let format = ''
      try {
        format = _monthly.split('-')[0] + _monthly.split('-')[1]
      } catch (ex) {
        console.log(ex)
      }
      return format
    }
  }
}
</script>

<style></style>
